import { createMedia } from "@artsy/fresnel"

export const AppMedia = createMedia({
  breakpoints: {
    xxs: 0,
    xs: 474,
    sm: 586,
    md: 908,
    lg: 1124,
    xl: 1366,
  },
})

export const { MediaContextProvider, Media } = AppMedia

export const mediaStyles = AppMedia.createMediaStyle()
