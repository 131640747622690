export default {
  useCustomProperties: false,
  space: [0, 4, 8, 16, 24, 27, 30, 42, 64, 96, 128, 192, 256, 384, 512],
  fontSizes: [11, 12, 13, 14, 16, 20, 24, 32, 42, 48, 64],
  fonts: {
    body:
      "ff-meta-web-pro, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif",
    heading:
      "ff-meta-web-pro, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif",
  },
  fontWeights: {
    heading: 400,
  },
  radii: {
    default: 0,
  },
  shadows: {
    outline: "0 0 3px 2px #6ee4ff",
    danger: "0 0 3px 2px #ED3838",
    overlay: "0 0 60px -20px rgba(0, 0, 0, 0.4)",
  },
  breakpoints: ["474px", "586px", "908px", "1124px", "1366px"],
  colors: {
    transparentWhite: "rgba(255,255,255,0.9)",
    primary: "#19AF5D",
    danger: "#ED3838",
    warning: "#FDC815",
    light: "#F5F5F5",
    dark: "#414141",
    darkGreen: "#393E3A",
    background: "#fff",
    text: "#707070",
  },
  buttons: {
    primary: {
      borderRadius: "default",
    },
    language: {
      backgroundColor: "transparent",
      p: 0,
      m: 0,
      appearance: "none",
      border: "none",
      cursor: "pointer",
    },
    showMore: {
      color: "text",
      backgroundColor: "transparent",
      p: 0,
      m: 0,
      my: 5,
      appearance: "none",
      border: "none",
      cursor: "pointer",
      position: "relative",
    },
  },
  forms: {
    input: {
      color: "text",
      borderRadius: "default",
      backgroundColor: "white",
      px: 3,
      mb: 3,
      transition: "none",
      "&:focus": {
        outline: "none",
        boxShadow: "outline",
      },
      "&.error": {
        boxShadow: "danger",
        mb: 0,
      },
    },
    textarea: {
      color: "text",
      borderRadius: "default",
      backgroundColor: "white",
      px: 3,
      mb: 3,
      transition: "none",
      "&:focus": {
        outline: "none",
        boxShadow: "outline",
      },
      "&.error": {
        boxShadow: "danger",
        mb: 0,
      },
    },
  },
  links: {
    nav: {
      color: "white",
      textTransform: "uppercase",
      textDecoration: "none",
      fontFamily: "body",
      "&:visited": {
        color: "white",
      },
    },
  },
  layout: {
    container: {
      px: [5, 7, 8, 5],
      maxWidth: ["none", "none", "none", "960px", "960px", "1024px"],
    },
  },
  styles: {
    root: {
      a: {
        color: "primary",
        "&:visited": {
          color: "text",
        },
      },
      p: {
        fontSize: [4, 4],
      },
      h1: {
        fontSize: 8,
      },
      h2: {
        fontSize: 7,
        "&.large": {
          fontSize: 8,
        },
      },
      h3: {
        fontSize: 6,
        "&.large": {
          fontSize: 7,
        },
      },
      h4: {
        fontSize: 5,
        mb: 4,
        "&.large": {
          fontSize: 6,
        },
      },
      h5: {
        fontSize: 4,
        "&.large": {
          fontSize: 5,
        },
      },
      ".headroom--pinned,.headroom--unfixed,.headroom--unpinned,.headroom--scrolled": {
        backgroundColor: "darkGreen",
      },
      ".slick-slide": {
        maxHeight: ["50vh", "50vh", "50vh", "none"],
      },
      "button:disabled": {
        opacity: 0.2,
        cursor: "not-allowed",
      },
    },
    hero: {
      fontSize: [7, 7, 8],
      lineHeight: ["42px", "42px", "52px"],
    },
  },
}
